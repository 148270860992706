(function($) {
  "use strict";

  $(function() {
    // Variablet
    var $duo_window = $(window),
      $body = $("body"),
      $wrapper = $("#wrapper"),
      $search_btn = $(".search-button a"),
      $main_header = $("#main-header"),
      $burger = $("#menu-burger"),
      $duo_parallax = $(".duo_parallax_bg"),
      $parallax_section = $(".duo-section-parallax"),
      $video_container = $(".embed-video-container"),
      $title_hero = $(".duo-section-title"),
      $submenu_switch = $(".open-submenu"),
      $map_container = $(".duo-map-container"),
      $prevScrollTop = 0;

    // Pikahaun avaus
    function quick_search() {
      $search_btn.click(function(event) {
        console.log("click search");
        event.preventDefault();
        $main_header.toggleClass("search-active");
        $("#quick-search-input").focus();
      });
    }

    // Mobiilivalikon avaus
    function open_mobile_nav() {
      $burger.click(function(event) {
        event.preventDefault();
        $main_header.toggleClass("menu-opened");
        $body.toggleClass("menu-open");
      });
    }

    // Mobiili valikon submenun avaus
    function open_submenu() {
      $submenu_switch.click(function(event) {
        event.preventDefault();
        var $submenu = $(this).next(".sub-menu");
        $submenu.slideToggle(150);
        $(this)
          .parent()
          .toggleClass("submenu-active");
      });

      // jos on current niin auki
      var $current_parent_li = $(
        "#menu-paavalikko > .current_page_item, #menu-paavalikko > .current_page_ancestor"
      );

      if ($current_parent_li.length) {
        $current_parent_li.addClass("submenu-active");
        $current_parent_li.find(".sub-menu").show();
      }
    }

    // Parallax efecti
    function apply_parallax() {
      var element_top = $parallax_section.offset().top,
        window_top = $duo_window.scrollTop(),
        y_pos = (window_top + $duo_window.height() - element_top) * 0.3,
        main_position;

      main_position = "translate3d(0, " + y_pos + "px, 0px)";

      $parallax_section.find(".duo_parallax_bg").css({
        "-webkit-transform": main_position,
        "-moz-transform": main_position,
        "-ms-transform": main_position,
        transform: main_position
      });

      var bg_height;

      bg_height = $duo_window.height() * 0.3 + $parallax_section.innerHeight();

      $duo_parallax.innerHeight(".duo_parallax_bg").css({ height: bg_height });
    }
    // Fixed desktop nav
    function fixed_header() {
      var $topNavHeight = $("#top-nav-wrapper").height();
      var $scrollTop = $duo_window.scrollTop();
      var $header = $("#wrapper");
      //console.log($scrollTop, $topNavHeight, $prevScrollTop);

      if ($scrollTop > $topNavHeight) {
        $header.addClass("fixed-header");
      } else {
        $header.removeClass("fixed-header");
      }

      // To hide fixed header in mobile when scrolling down and revealing when scrolling up
      if ($scrollTop > $prevScrollTop && $scrollTop > 152) {
        $header.addClass("scroll-down");
      } else {
        $header.removeClass("scroll-down");
      }
      $prevScrollTop = $scrollTop;
    }

    // Funktiot käyntiin

    $(document).ready(function() {
      if ($main_header.length) {
        $duo_window.scroll(fixed_header);
        fixed_header();
      }
      if ($search_btn.length && !$(".search-results").length) {
        quick_search();
      }

      if ($burger.length) {
        open_mobile_nav();
      }
      // jquery.fitvids.js
      if ($video_container.length) {
        $video_container.fitVids();

        $video_container.fitVids({
          customSelector: "iframe[src^='http://socialcam.com']"
        });
      }
      if ($submenu_switch.length) {
        open_submenu();
      }

      $(window).load(function() {
        var viewport = $(window).innerWidth();
        var wH = $(window).height();
        // Vain desktop
        if (viewport > 1209) {
          //
          if ($duo_parallax.length) {
            apply_parallax();
            $duo_window.on("scroll", apply_parallax);
          }
        }
        if (viewport < 990) {
          // $('.hero-unit').css({ 'max-height' : wH +'px'});
        }
      });

      /* Google maps juttu ota yhteyttä sivulla, tämä postettu käytöstä koska Gmaps policyt muttui
      if ($map_container.length) {
        $map_container.each(function() {
          // create map
          $map_container = new_map($(this)); // new_map() -> kts. duo-google-maps.js
        });
      }*/
    });
  });
})(jQuery);
